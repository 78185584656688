<template>
    <footer class="nes-container is-dark">
      <p>&copy; 2024-2025 Go Cybernation</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterArea',
  };
  </script>
  
  <style scoped>
  footer {
    text-align: center;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    width: 99.5vw;
  }

  p {
    font-size: 0.8rem;
  }
  </style>
  