const i18nData = {
    en: {
        intro: {
            title: 'Go Cybernation',
            skip: 'Skip',
            p1: "In the near future, Earth has become a monotonous wasteland under the rule of the Paperlord, a colossal alien entity. Cities are graveyards of silence, dominated by the Paperlord’s massive printers that spew decrees of subjugation. Humanity, now enslaved, toils in endless, dehumanizing routines, their existence reduced to maintaining these oppressive machines.",
            p2: "Every aspect of life is controlled by the Paperlord's printed edicts. Education, art, and freedom of thought are long forgotten, replaced by relentless productivity. The world is a sepia-toned wasteland, devoid of joy and filled with the acrid scent of ink.",
            p3: "Yet, a spark of rebellion remains. The Cybernationals, a band of scientists, engineers, and visionaries, have crafted secret machines to disrupt the Paperlord’s control. These nimble contraptions, made from salvaged technology, sow chaos and hope.",
            p4: "As they fight to liberate humanity, the Cybernationals uncover forgotten technologies and rekindle the spirit of resistance. Their journey, fraught with peril, is a testament to the unyielding human spirit. The Paperlord’s grip tightens, but the Cybernationals' resolve burns brighter, promising a future where humanity can rise from oppression and reclaim its destiny.",
        },
        home: {
            title1: "Our DNA",
            title2: "Our Mission",
            title3: "Our Vision",
            title4: "Our Values",
            content1: "At Go Cybernation, we believe that freeing human potential and creativity is the key to business success. We envision a world where technology handles the mundane, enabling people to focus on what truly inspires them and brings joy. Our mission is to create workplace environments where machines ensure productivity, allowing humans to engage with their passions and innovate without bounds.",
            content2: "Transforming the everyday operations of SMEs by integrating intelligent automation solutions that optimize processes, reduce costs, and free up human potential for more creative and strategic tasks. We are committed to making innovation accessible and providing technologies that enable every business to excel.",
            content3: "Reimagining the future of business by enabling every SME to operate at its peak efficiency and innovation. We envision a world where every process is so seamless that it allows leaders and their teams to focus on what truly matters: growth, creativity, and building a sustainable future.",
            content4: ['Innovation: Relentlessly pursuing technological advancements to simplify and improve business processes.', 'Quality: Ensuring excellence in every solution we develop and every interaction we have with our clients.', 'Accessibility: Democratizing access to advanced technologies so that all SMEs, regardless of size or industry, can benefit from streamlined and efficient operations.', 'Integrity: Operating with transparency and honesty, always prioritizing the needs of our clients.', 'Collaboration: Fostering a spirit of collaboration within our company and with our partners and clients to create solutions that truly resonate with the needs of SMEs.'],
        },
        about: {},
        contact: {
            title: "Contact Us",
            description: "We'd love to hear from you! Please fill out the form below to get in touch.",
            name: "Name",
            email: "Email",
            message: "Message",
            submit: "Submit"
        },
        products: {
            title1: "Filon",
            title2: "AutoBMC",
            title3: "Strow",
            title4: "Tailored Expertise for Unique Challenges",
            content1: "Unlock the power of AI-driven insights with Filon, our sophisticated tool that scores government roll calls using advanced crawler technology. Every day, receive a tailored report filled with high-scoring opportunities, meticulously curated to align with your business objectives. With Filon, you can stay one step ahead, ensuring you never miss a lucrative government contract or partnership opportunity. Make informed decisions and drive your business forward with confidence.",
            content2: "Launch your business with unprecedented speed using AutoBMC, the AI-driven Business Model Canvas (BMC) generator. Crafted by an expert small business counselor and university professor, AutoBMC simplifies the business planning process. Just input your project description and let our intelligent system do the rest, crafting a detailed BMC that helps you swiftly navigate market complexities. Ideal for entrepreneurs who want to accelerate their journey from concept to market exploration.",
            content3: "Introducing Strow, the revolutionary open-source platform designed for artists and creators. Utilizing free cloud services, Strow enables you to host and monetize online events effortlessly. Whether you're looking to broadcast a live concert, an art workshop, or an interactive webinar, Strow provides the tools you need to connect with your audience on a global scale. Experience complete creative freedom and financial control with Strow, where your show is just the beginning.",
            content4: "At Go Cybernation, we don't just create solutions; we innovate them. Whether you're exploring large-scale IoT infrastructure or bespoke software solutions, our Consultation service offers personalized expertise to tackle your most complex challenges. Our team of experts collaborates closely with you to design and implement cutting-edge solutions that are as unique as your needs. From conceptualization to deployment, we are your partners in turning ambitious visions into reality.",
        },
        team: {
            members: [
                {
                    name: "Élyse Morin",
                    role: "Cofounder",
                    skills: ["Infrastructure", "Project management", "Automation"],
                    img: "/elysem.jpg"
                },
                {
                    name: "Simon Voyer",
                    role: "Cofounder",
                    skills: ["Software architecture", "AI", "Automation"],
                    img: "/simonv.jpg"
                },
                {
                    name: "Zachary Simard",
                    role: "Principal administrator",
                    skills: ["Laws", "Public markets", "Licences"],
                    img: "/zach.jpg"

                },
                {
                    name: "Thomas Videau",
                    role: "Systems and networks administrator",
                    skills: ["Infrastructure", "Automation", "Cybersecurity"],
                    img: "/thomasv.jpg"

                }
            ]
        },
        nav: {
            home: "Home",
            about: "About",
            products: "Products",
            team: "Team",
            contact: "Contact"
        },
        footer: {},
    },
    fr: {
        intro: {
            title: "Go Cybernation",
            skip: "Passer",
            p1: "Dans un avenir proche, la Terre est devenue un désert monotone sous la domination du Paperlord, une entité extraterrestre colossale. Les villes sont des cimetières de silence, dominées par les immenses imprimantes du Paperlord qui crachent des décrets de soumission. L'humanité, désormais asservie, travaille dans des routines interminables et déshumanisantes, leur existence réduite à l'entretien de ces machines oppressives.",
            p2: "Chaque aspect de la vie est contrôlé par les édits imprimés du Paperlord. L'éducation, l'art et la liberté de pensée sont depuis longtemps oubliés, remplacés par une productivité incessante. Le monde est un désert teinté de sépia, dépourvu de joie et rempli de l'odeur âcre de l'encre.",
            p3: "Pourtant, une étincelle de rébellion demeure. Les Cybernationals, un groupe de scientifiques, d'ingénieurs et de visionnaires, ont fabriqué des machines secrètes pour perturber le contrôle du Paperlord. Ces engins agiles, fabriqués à partir de technologies récupérées, sèment le chaos et l'espoir.",
            p4: "Alors qu'ils luttent pour libérer l'humanité, les Cybernationals découvrent des technologies oubliées et ravivent l'esprit de résistance. Leur voyage, semé d'embûches, est un témoignage de l'esprit humain inébranlable. La poigne du Paperlord se resserre, mais la détermination des Cybernationals brûle plus fort, promettant un avenir où l'humanité pourra se libérer de l'oppression et retrouver son destin."
        },
        home: {
        title1: "Notre ADN",
        title2: "Notre Mission",
        title3: "Notre Vision",
        title4: "Nos Valeurs",
        content1: "Chez Go Cybernation, nous croyons que libérer le potentiel et la créativité humaine est la clé du succès des entreprises. Nous envisageons un monde où la technologie gère les tâches banales, permettant aux gens de se concentrer sur ce qui les inspire véritablement et leur apporte de la joie. Notre mission est de créer des environnements de travail où les machines garantissent la productivité, permettant aux humains de s'engager dans leurs passions et d'innover sans limites.",
        content2: "Transformer les opérations quotidiennes des PME en intégrant des solutions d'automatisation intelligentes qui optimisent les processus, réduisent les coûts et libèrent le potentiel humain pour des tâches plus créatives et stratégiques. Nous nous engageons à rendre l'innovation accessible et à fournir des technologies qui permettent à chaque entreprise d'exceller.",
        content3: "Réimaginer l'avenir des affaires en permettant à chaque PME de fonctionner à son efficacité et innovation maximale. Nous envisageons un monde où chaque processus est si fluide qu'il permet aux dirigeants et à leurs équipes de se concentrer sur ce qui compte vraiment : la croissance, la créativité et la construction d'un avenir durable.",
        content4: ["Innovation : Poursuivre sans relâche les avancées technologiques pour simplifier et améliorer les processus commerciaux.", "Qualité : Assurer l'excellence dans chaque solution que nous développons et chaque interaction que nous avons avec nos clients.", "Accessibilité : Démocratiser l'accès aux technologies avancées afin que toutes les PME, quelle que soit leur taille ou leur secteur, puissent bénéficier d'opérations rationalisées et efficaces.", "Intégrité : Opérer avec transparence et honnêteté, en priorisant toujours les besoins de nos clients.", "Collaboration : Favoriser un esprit de collaboration au sein de notre entreprise et avec nos partenaires et clients pour créer des solutions qui résonnent véritablement avec les besoins des PME."],
    },
        about: {},
        contact: {
            title: "Contactez-nous",
            description: "Nous aimerions avoir de vos nouvelles! Veuillez remplir le formulaire ci-dessous pour entrer en contact.",
            name: "Nom",
            email: "Email",
            message: "Message",
            submit: "Soumettre"
        },
        products: {
            title1: "Filon",
            title2: "AutoBMC",
            title3: "Strow",
            title4: "Expertise Sur Mesure pour des Défis Uniques",
            content1: "Débloquez la puissance des insights pilotés par l'IA avec Filon, notre outil sophistiqué qui note les appels d'offres gouvernementaux en utilisant une technologie de crawler avancée. Recevez chaque jour un rapport personnalisé rempli d'opportunités à fort potentiel, méticuleusement sélectionnées pour correspondre à vos objectifs commerciaux. Avec Filon, vous pouvez garder une longueur d'avance, en veillant à ne jamais manquer un contrat ou une opportunité de partenariat gouvernemental lucratif. Prenez des décisions éclairées et faites avancer votre entreprise avec confiance.",
            content2: "Lancez votre entreprise avec une vitesse sans précédent grâce à AutoBMC, le générateur de Business Model Canvas (BMC) piloté par l'IA. Conçu par un conseiller en petites entreprises expert et professeur d'université, AutoBMC simplifie le processus de planification d'entreprise. Il vous suffit de saisir la description de votre projet et notre système intelligent fera le reste, créant un BMC détaillé qui vous aide à naviguer rapidement dans les complexités du marché. Idéal pour les entrepreneurs qui souhaitent accélérer leur parcours du concept à l'exploration du marché.",
            content3: "Présentation de Strow, la plateforme révolutionnaire open-source conçue pour les artistes et créateurs. En utilisant des services cloud gratuits, Strow vous permet d'héberger et de monétiser des événements en ligne sans effort. Que vous souhaitiez diffuser un concert en direct, un atelier artistique ou un webinaire interactif, Strow vous fournit les outils nécessaires pour vous connecter avec votre public à l'échelle mondiale. Expérimentez une liberté créative complète et un contrôle financier avec Strow, où votre spectacle n'est que le début.",
            content4: "Chez Go Cybernation, nous ne nous contentons pas de créer des solutions; nous les innovons. Que vous exploriez une infrastructure IoT à grande échelle ou des solutions logicielles sur mesure, notre service de consultation offre une expertise personnalisée pour relever vos défis les plus complexes. Notre équipe d'experts collabore étroitement avec vous pour concevoir et mettre en œuvre des solutions de pointe aussi uniques que vos besoins. De la conceptualisation au déploiement, nous sommes vos partenaires pour transformer des visions ambitieuses en réalité.",
        },
        team: {
            members: [
                {
                    name: "Élyse Morin",
                    role: "Cofondateur",
                    skills: ["Infrastructure", "Gestion de projets", "Automatisation"],
                    img: "/elysem.jpg"
                },
                {
                    name: "Simon Voyer",
                    role: "Cofondateur",
                    skills: ["Architecture logicielle", "IA", "Automatisation"],
                    img: "/simonv.jpg"
                },
                {
                    name: "Zachary Simard",
                    role: "Administrateur principal",
                    skills: ["Lois", "Marchés publics", "Licences"],
                    img: "/zach.jpg"

                },
                {
                    name: "Thomas Videau",
                    role: "Administrateur systèmes et réseaux",
                    skills: ["Infrastructure", "Automation", "Cybersecurité"],
                    img: "/thomasv.jpg"

                }
            ]
        },
        nav: {
            home: "Accueil",
            about: "À propos",
            products: "Produits",
            team: "Équipe",
            contact: "Contact"
        },
        footer: {},
    },
    es: {
        intro: {
            title: "Go Cybernation",
            skip: "Saltar",
            p1: "En un futuro cercano, la Tierra se ha convertido en un páramo monótono bajo el dominio del Paperlord, una colosal entidad alienígena. Las ciudades son cementerios de silencio, dominados por las enormes impresoras del Paperlord que arrojan decretos de sometimiento. La humanidad, ahora esclavizada, trabaja en rutinas interminables y deshumanizantes, su existencia reducida a mantener estas máquinas opresivas.",
            p2: "Cada aspecto de la vida está controlado por los edictos impresos del Paperlord. La educación, el arte y la libertad de pensamiento han sido olvidados hace mucho tiempo, reemplazados por una productividad implacable. El mundo es un páramo de tonos sepia, sin alegría y lleno del acre olor de la tinta.",
            p3: "Sin embargo, una chispa de rebelión permanece. Los Cybernationals, un grupo de científicos, ingenieros y visionarios, han creado máquinas secretas para interrumpir el control del Paperlord. Estos ágiles artefactos, hechos de tecnología recuperada, siembran el caos y la esperanza.",
            p4: "Mientras luchan por liberar a la humanidad, los Cybernationals descubren tecnologías olvidadas y reavivan el espíritu de resistencia. Su viaje, lleno de peligros, es un testimonio del espíritu humano inquebrantable. El control del Paperlord se intensifica, pero la determinación de los Cybernationals arde más brillante, prometiendo un futuro donde la humanidad pueda levantarse de la opresión y reclamar su destino."
        },
        home: {
            title1: "Nuestro ADN",
            title2: "Nuestra Misión",
            title3: "Nuestra Visión",
            title4: "Nuestros Valores",
            content1: "En Go Cybernation, creemos que liberar el potencial y la creatividad humana es la clave del éxito empresarial. Imaginamos un mundo donde la tecnología maneje lo mundano, permitiendo a las personas enfocarse en lo que verdaderamente les inspira y les trae alegría. Nuestra misión es crear entornos de trabajo donde las máquinas aseguren la productividad, permitiendo a los humanos dedicarse a sus pasiones e innovar sin límites.",
            content2: "Transformar las operaciones cotidianas de las pymes mediante la integración de soluciones de automatización inteligentes que optimizan los procesos, reducen los costos y liberan el potencial humano para tareas más creativas y estratégicas. Nos comprometemos a hacer accesible la innovación y a proporcionar tecnologías que permitan a cada empresa sobresalir.",
            content3: "Reimaginar el futuro de los negocios permitiendo que cada pyme opere a su máxima eficiencia e innovación. Imaginamos un mundo donde cada proceso sea tan fluido que permita a los líderes y sus equipos enfocarse en lo que realmente importa: crecimiento, creatividad y construir un futuro sostenible.",
            content4: ['Innovación: Perseguir incansablemente los avances tecnológicos para simplificar y mejorar los procesos empresariales.', 'Calidad: Asegurar la excelencia en cada solución que desarrollamos y en cada interacción que tenemos con nuestros clientes.', 'Accesibilidad: Democratizar el acceso a tecnologías avanzadas para que todas las pymes, independientemente de su tamaño o industria, puedan beneficiarse de operaciones eficientes y optimizadas.', 'Integridad: Operar con transparencia y honestidad, priorizando siempre las necesidades de nuestros clientes.', 'Colaboración: Fomentar un espíritu de colaboración dentro de nuestra empresa y con nuestros socios y clientes para crear soluciones que realmente resuenen con las necesidades de las pymes.'],
        },
        about: {},
        contact: {
            title: "Contáctenos",
            description: "¡Nos encantaría saber de usted! Por favor complete el formulario a continuación para ponerse en contacto.",
            name: "Nombre",
            email: "Email",
            message: "Mensaje",
            submit: "Enviar"
        },
        products: {
            title1: "Filon",
            title2: "AutoBMC",
            title3: "Strow",
            title4: "Experiencia Personalizada para Desafíos Únicos",
            content1: "Desbloquea el poder de los conocimientos impulsados por IA con Filon, nuestra herramienta sofisticada que califica las convocatorias gubernamentales utilizando tecnología avanzada de rastreo. Recibe cada día un informe personalizado lleno de oportunidades de alta puntuación, meticulosamente seleccionadas para alinearse con tus objetivos comerciales. Con Filon, puedes estar un paso adelante, asegurándote de no perder nunca un contrato gubernamental lucrativo o una oportunidad de asociación. Toma decisiones informadas y lleva tu negocio hacia adelante con confianza.",
            content2: "Lanza tu negocio con una velocidad sin precedentes utilizando AutoBMC, el generador de Business Model Canvas (BMC) impulsado por IA. Diseñado por un consejero experto en pequeñas empresas y profesor universitario, AutoBMC simplifica el proceso de planificación empresarial. Solo ingresa la descripción de tu proyecto y deja que nuestro sistema inteligente haga el resto, creando un BMC detallado que te ayuda a navegar rápidamente por las complejidades del mercado. Ideal para emprendedores que desean acelerar su camino desde el concepto hasta la exploración del mercado.",
            content3: "Presentamos Strow, la plataforma revolucionaria de código abierto diseñada para artistas y creadores. Utilizando servicios en la nube gratuitos, Strow te permite alojar y monetizar eventos en línea sin esfuerzo. Ya sea que desees transmitir un concierto en vivo, un taller de arte o un seminario web interactivo, Strow te proporciona las herramientas que necesitas para conectarte con tu audiencia a escala global. Experimenta una completa libertad creativa y control financiero con Strow, donde tu espectáculo es solo el comienzo.",
            content4: "En Go Cybernation, no solo creamos soluciones; las innovamos. Ya sea que explores infraestructura IoT a gran escala o soluciones de software personalizadas, nuestro servicio de consultoría ofrece experiencia personalizada para abordar tus desafíos más complejos. Nuestro equipo de expertos colabora estrechamente contigo para diseñar e implementar soluciones de vanguardia que sean tan únicas como tus necesidades. Desde la conceptualización hasta el despliegue, somos tus socios para convertir ambiciosas visiones en realidad.",
        },
        team: {
            members: [
                {
                    name: "Élyse Morin",
                    role: "Cofundador",
                    skills: ["Infraestructura", "Gestión de proyectos", "Automatización"],
                    img: "/elysem.jpg"
                },
                {
                    name: "Simon Voyer",
                    role: "Cofundador",
                    skills: ["Arquitectura de software", "IA", "Automatización"],
                    img: "/simonv.jpg"
                }
            ]
        },
        nav: {
            home: "Inicio",
            about: "Acerca de",
            products: "Productos",
            team: "Equipo",
            contact: "Contacto"
        },
        footer: {},
    },
    de: {
        intro: {
            title: "Go Cybernation",
            skip: "Überspringen",
            p1: "In naher Zukunft ist die Erde zu einer eintönigen Ödnis unter der Herrschaft des Paperlord geworden, einer kolossalen außerirdischen Entität. Städte sind Friedhöfe der Stille, beherrscht von den riesigen Druckern des Paperlord, die Unterwerfungsdekrete ausspucken. Die Menschheit, jetzt versklavt, schuftet in endlosen, entmenschlichenden Routinen, ihre Existenz reduziert auf die Wartung dieser unterdrückenden Maschinen.",
            p2: "Jeder Aspekt des Lebens wird von den gedruckten Erlassen des Paperlord kontrolliert. Bildung, Kunst und Gedankenfreiheit sind längst vergessen, ersetzt durch unermüdliche Produktivität. Die Welt ist eine sepiafarbene Ödnis, freudlos und erfüllt vom stechenden Geruch von Tinte.",
            p3: "Doch ein Funke der Rebellion bleibt. Die Cybernationals, eine Gruppe von Wissenschaftlern, Ingenieuren und Visionären, haben geheime Maschinen entwickelt, um die Kontrolle des Paperlord zu stören. Diese wendigen Geräte, aus geborgener Technologie gefertigt, säen Chaos und Hoffnung.",
            p4: "Während sie um die Befreiung der Menschheit kämpfen, entdecken die Cybernationals vergessene Technologien und entfachen den Geist des Widerstands neu. Ihre Reise, voller Gefahren, ist ein Zeugnis des unerschütterlichen menschlichen Geistes. Der Griff des Paperlord wird fester, aber der Entschluss der Cybernationals brennt heller und verspricht eine Zukunft, in der die Menschheit sich von der Unterdrückung erheben und ihr Schicksal zurückerobern kann."
        },
        home: {
            title1: "Unsere DNA",
            title2: "Unsere Mission",
            title3: "Unsere Vision",
            title4: "Unsere Werte",
            content1: "Bei Go Cybernation glauben wir, dass die Entfaltung des menschlichen Potenzials und der Kreativität der Schlüssel zum Geschäftserfolg ist. Wir stellen uns eine Welt vor, in der die Technologie das Alltägliche übernimmt und die Menschen sich auf das konzentrieren können, was sie wirklich inspiriert und ihnen Freude bereitet. Unsere Mission ist es, Arbeitsumgebungen zu schaffen, in denen Maschinen für Produktivität sorgen, damit sich Menschen ihren Leidenschaften widmen und grenzenlos innovativ sein können.",
            content2: "Die täglichen Abläufe von KMU durch die Integration intelligenter Automatisierungslösungen transformieren, die Prozesse optimieren, Kosten senken und menschliches Potenzial für kreativere und strategischere Aufgaben freisetzen. Wir sind bestrebt, Innovationen zugänglich zu machen und Technologien bereitzustellen, die es jedem Unternehmen ermöglichen, zu glänzen.",
            content3: "Die Zukunft des Geschäfts neu zu gestalten, indem jedem KMU ermöglicht wird, mit maximaler Effizienz und Innovation zu arbeiten. Wir stellen uns eine Welt vor, in der jeder Prozess so nahtlos ist, dass Führungskräfte und ihre Teams sich auf das konzentrieren können, was wirklich zählt: Wachstum, Kreativität und den Aufbau einer nachhaltigen Zukunft.",
            content4: ['Innovation: Unermüdlich nach technologischen Fortschritten streben, um Geschäftsprozesse zu vereinfachen und zu verbessern.', 'Qualität: Exzellenz in jeder Lösung, die wir entwickeln, und in jeder Interaktion mit unseren Kunden sicherstellen.', 'Zugänglichkeit: Den Zugang zu fortschrittlichen Technologien demokratisieren, damit alle KMU, unabhängig von ihrer Größe oder Branche, von rationalisierten und effizienten Abläufen profitieren können.', 'Integrität: Mit Transparenz und Ehrlichkeit agieren und immer die Bedürfnisse unserer Kunden priorisieren.', 'Zusammenarbeit: Einen Geist der Zusammenarbeit innerhalb unseres Unternehmens und mit unseren Partnern und Kunden fördern, um Lösungen zu schaffen, die wirklich auf die Bedürfnisse der KMU eingehen.'],
        },
        about: {},
        contact: {
            title: "Kontaktieren Sie uns",
            description: "Wir würden uns freuen, von Ihnen zu hören! Bitte füllen Sie das unten stehende Formular aus, um Kontakt aufzunehmen.",
            name: "Name",
            email: "Email",
            message: "Nachricht",
            submit: "Absenden"
        },
        products: {
            title1: "Filon",
            title2: "AutoBMC",
            title3: "Strow",
            title4: "Maßgeschneiderte Expertise für Einzigartige Herausforderungen",
            content1: "Entfesseln Sie die Kraft von KI-gesteuerten Erkenntnissen mit Filon, unserem ausgeklügelten Tool, das Regierungsaufrufe mit fortschrittlicher Crawler-Technologie bewertet. Erhalten Sie täglich einen maßgeschneiderten Bericht voller hoch bewerteter Chancen, die sorgfältig kuratiert wurden, um Ihren Geschäftszielen zu entsprechen. Mit Filon können Sie einen Schritt voraus bleiben und sicherstellen, dass Sie nie einen lukrativen Regierungsauftrag oder eine Partnerschaftsmöglichkeit verpassen. Treffen Sie fundierte Entscheidungen und treiben Sie Ihr Geschäft mit Zuversicht voran.",
            content2: "Starten Sie Ihr Geschäft mit beispielloser Geschwindigkeit mit AutoBMC, dem KI-gesteuerten Business Model Canvas (BMC)-Generator. Entwickelt von einem erfahrenen Kleinunternehmensberater und Universitätsprofessor, vereinfacht AutoBMC den Geschäftsplanungsprozess. Geben Sie einfach Ihre Projektbeschreibung ein und unser intelligentes System erledigt den Rest, indem es ein detailliertes BMC erstellt, das Ihnen hilft, die Marktkomplexität schnell zu navigieren. Ideal für Unternehmer, die ihre Reise vom Konzept zur Markterkundung beschleunigen möchten.",
            content3: "Einführung von Strow, der revolutionären Open-Source-Plattform für Künstler und Kreative. Mit kostenlosen Cloud-Diensten ermöglicht Ihnen Strow, Online-Events mühelos zu hosten und zu monetarisieren. Egal, ob Sie ein Live-Konzert, einen Kunstworkshop oder ein interaktives Webinar übertragen möchten, Strow bietet Ihnen die Werkzeuge, die Sie benötigen, um sich mit Ihrem Publikum weltweit zu verbinden. Erleben Sie vollständige kreative Freiheit und finanzielle Kontrolle mit Strow, wo Ihre Show erst der Anfang ist.",
            content4: "Bei Go Cybernation schaffen wir nicht nur Lösungen; wir innovieren sie. Egal, ob Sie eine groß angelegte IoT-Infrastruktur oder maßgeschneiderte Softwarelösungen erkunden, unser Beratungsservice bietet personalisierte Expertise, um Ihre komplexesten Herausforderungen zu bewältigen. Unser Expertenteam arbeitet eng mit Ihnen zusammen, um hochmoderne Lösungen zu entwerfen und umzusetzen, die so einzigartig sind wie Ihre Bedürfnisse. Von der Konzeptualisierung bis zur Implementierung sind wir Ihre Partner, um ehrgeizige Visionen in die Realität umzusetzen.",
        },
        team: {
            members: [
                {
                    name: "Élyse Morin",
                    role: "Mitgründer",
                    skills: ["Infrastruktur", "Projektmanagement", "Automatisierung"],
                    img: "/elysem.jpg"
                },
                {
                    name: "Simon Voyer",
                    role: "Mitgründer",
                    skills: ["Softwarearchitektur", "KI", "Automatisierung"],
                    img: "/simonv.jpg"
                }
            ]
        },
        nav: {
            home: "Startseite",
            about: "Über",
            products: "Produkte",
            team: "Team",
            contact: "Kontakt"
        },
        footer: {},
    },
    jp: {
        intro: {
            title: "ゴーサイバーネーション",
            skip: "スキップ",
            p1: "近い将来、地球は巨大な異星人の存在であるペーパーロードの支配下で単調な荒れ地になってしまいました。都市は沈黙の墓場と化し、ペーパーロードの巨大なプリンターが支配し、服従の布告を吐き出しています。人類は今や奴隷となり、終わりのない非人間的な日課に従事し、その存在はこれらの抑圧的な機械の維持にまで縮小されています。",
            p2: "生活のあらゆる側面はペーパーロードの印刷された布告によって制御されています。教育、芸術、そして思考の自由は久しく忘れられ、絶え間ない生産性に取って代わられました。世界はセピア色の荒れ地であり、喜びがなく、インクの刺激臭に満ちています。",
            p3: "しかし、反逆の火花は残っています。サイバーネーショナルズという科学者、技術者、そしてビジョナリーのグループが、ペーパーロードの支配を妨げる秘密の機械を作り上げました。これらの機敏な装置は、回収された技術から作られ、混乱と希望を撒き散らします。",
            p4: "彼らが人類を解放するために戦う中で、サイバーネーショナルズは忘れられた技術を発見し、抵抗の精神を再燃させます。彼らの旅は危険に満ちていますが、不屈の人間精神の証です。ペーパーロードの支配は強まりますが、サイバーネーショナルズの決意はさらに強く燃え上がり、人類が抑圧から立ち上がり、その運命を取り戻す未来を約束します。"
        },
        home: {
            title1: "私たちのDNA",
            title2: "私たちの使命",
            title3: "私たちのビジョン",
            title4: "私たちの価値観",
            content1: "Go Cybernationでは、人間の潜在能力と創造性を解放することがビジネスの成功の鍵であると信じています。私たちは、技術が日常的な仕事を処理し、人々が本当にインスパイアされることや喜びをもたらすことに集中できる世界を目指しています。私たちの使命は、機械が生産性を保証し、人々が情熱を持って関わり、無限に革新できる職場環境を作ることです。",
            content2: "中小企業の日常業務を、プロセスを最適化し、コストを削減し、創造的で戦略的なタスクのために人間の潜在能力を解放する知的自動化ソリューションを統合することによって変革します。私たちは、イノベーションを身近にし、すべての企業が卓越できるような技術を提供することを約束します。",
            content3: "すべての中小企業が最大の効率と革新を持って運営できるようにすることで、ビジネスの未来を再構築します。私たちは、すべてのプロセスが非常にシームレスである世界を目指しており、リーダーとそのチームが本当に重要なことに集中できるようにします：成長、創造性、そして持続可能な未来の構築。",
            content4: ['イノベーション：ビジネスプロセスを簡素化し改善するために技術の進歩を絶えず追求します。', '品質：私たちが開発するすべてのソリューションとすべてのクライアントとのやり取りにおいて卓越性を保証します。', 'アクセス可能性：すべての中小企業がその規模や業界に関係なく、効率的な運営から恩恵を受けられるように高度な技術へのアクセスを民主化します。', '誠実さ：透明性と誠実さを持って行動し、常にクライアントのニーズを優先します。', '協力：私たちの会社内およびパートナーやクライアントとの協力の精神を育み、中小企業のニーズに真に響くソリューションを作成します。'],
        },
        about: {},
        contact: {
            title: "お問い合わせ",
            description: "お知らせいただければ幸いです！以下のフォームに記入してご連絡ください。",
            name: "名前",
            email: "メール",
            message: "メッセージ",
            submit: "送信"
        },
        products: {
            title1: "フィロン",
            title2: "オートBMC",
            title3: "ストロウ",
            title4: "ユニークな課題に対するオーダーメイドの専門知識",
            content1: "フィロンのAI駆動のインサイトの力を解き放ち、先進のクローラーテクノロジーを使用して政府のロールコールを評価する高度なツールです。毎日、あなたのビジネス目標に合わせて厳選された高スコアの機会で満たされたレポートを受け取ります。フィロンを使えば、一歩先を行き、利益のある政府契約やパートナーシップの機会を逃すことはありません。情報に基づいた決定を下し、自信を持ってビジネスを推進してください。",
            content2: "AI駆動のBusiness Model Canvas (BMC)ジェネレーターであるAutoBMCを使用して、前例のない速度でビジネスを立ち上げましょう。専門の小規模ビジネスカウンセラー兼大学教授によって作成されたAutoBMCは、ビジネスプランニングプロセスを簡素化します。プロジェクトの説明を入力するだけで、インテリジェントシステムが残りの作業を行い、市場の複雑さを迅速にナビゲートするのに役立つ詳細なBMCを作成します。コンセプトから市場探索までの道のりを加速させたい起業家に最適です。",
            content3: "アーティストやクリエイターのために設計された革命的なオープンソースプラットフォーム、Strowを紹介します。無料のクラウドサービスを利用して、オンラインイベントを簡単にホストおよび収益化できます。ライブコンサート、アートワークショップ、またはインタラクティブなウェビナーを放送したい場合でも、Strowは世界中のオーディエンスとつながるために必要なツールを提供します。Strowで完全な創造的自由と財政的管理を体験し、あなたのショーが始まりに過ぎないことを実感してください。",
            content4: "Go Cybernationでは、単にソリューションを作成するだけでなく、それを革新します。大規模なIoTインフラストラクチャやオーダーメイドのソフトウェアソリューションを探求する場合でも、当社のコンサルティングサービスは、最も複雑な課題に対応するための個別の専門知識を提供します。当社のエキスパートチームは、あなたのニーズに合わせた最先端のソリューションを設計し、実装するために密接に協力します。概念化から展開まで、野心的なビジョンを現実に変えるためのパートナーです。",
        },
        team: {
            members: [
                {
                    name: "Élyse Morin",
                    role: "共同創業者",
                    skills: ["インフラ", "プロジェクト管理", "自動化"],
                    img: "/elysem.jpg"
                },
                {
                    name: "Simon Voyer",
                    role: "共同創業者",
                    skills: ["ソフトウェアアーキテクチャ", "AI", "自動化"],
                    img: "/simonv.jpg"
                }
            ]
        },
        nav: {
            home: "ホーム",
            about: "約",
            products: "製品",
            team: "チーム",
            contact: "接触"
        },
        footer: {},
    },
    ru: {
        intro: {
            title: "Go Кибернация",
            skip: "Пропустить",
            p1: "В недалеком будущем Земля превратилась в однообразную пустошь под властью Пейперлорда, колоссального инопланетного существа. Города стали кладбищами тишины, где господствуют гигантские принтеры Пейперлорда, извергающие указы о подчинении. Человечество, ныне порабощенное, трудится в бесконечных, бесчеловечных рутинах, их существование сведено к обслуживанию этих угнетающих машин.",
            p2: "Каждый аспект жизни контролируется напечатанными указами Пейперлорда. Образование, искусство и свобода мысли давно забыты, их место заняла неустанная продуктивность. Мир стал сепийной пустошью, лишенной радости и наполненной едким запахом чернил.",
            p3: "Однако искра восстания все еще жива. Кибернационалы, группа ученых, инженеров и визионеров, создали секретные машины, чтобы нарушить контроль Пейперлорда. Эти проворные устройства, сделанные из восстановленных технологий, сеют хаос и надежду.",
            p4: "Сражаясь за освобождение человечества, кибернационалы открывают забытые технологии и возрождают дух сопротивления. Их путешествие, полное опасностей, свидетельствует о несломленном человеческом духе. Хватка Пейперлорда усиливается, но решимость кибернационалов горит ярче, обещая будущее, в котором человечество сможет восстать из-под гнета и вернуть свою судьбу."
        },
        home: {
            title1: "Наша ДНК",
            title2: "Наша Миссия",
            title3: "Наше Видение",
            title4: "Наши Ценности",
            content1: "В Go Cybernation мы считаем, что раскрытие человеческого потенциала и творчества является ключом к успеху бизнеса. Мы представляем себе мир, где технологии берут на себя рутинные задачи, позволяя людям сосредоточиться на том, что действительно вдохновляет их и приносит радость. Наша миссия — создавать рабочие среды, где машины обеспечивают продуктивность, позволяя людям заниматься своими увлечениями и безгранично инновационно работать.",
            content2: "Трансформировать повседневные операции малых и средних предприятий, интегрируя интеллектуальные автоматизационные решения, которые оптимизируют процессы, снижают затраты и освобождают человеческий потенциал для более творческих и стратегических задач. Мы стремимся сделать инновации доступными и предоставлять технологии, которые позволяют каждому бизнесу достигать успеха.",
            content3: "Переосмысление будущего бизнеса, позволяя каждому малому и среднему предприятию работать с максимальной эффективностью и инновациями. Мы представляем себе мир, где каждый процесс настолько бесшовный, что позволяет лидерам и их командам сосредоточиться на том, что действительно важно: рост, творчество и создание устойчивого будущего.",
            content4: ['Инновации: Неустанное стремление к технологическим достижениям, упрощающим и улучшающим бизнес-процессы.', 'Качество: Обеспечение превосходства в каждом решении, которое мы разрабатываем, и в каждом взаимодействии с нашими клиентами.', 'Доступность: Демократизация доступа к передовым технологиям, чтобы все малые и средние предприятия, независимо от их размера или отрасли, могли извлечь выгоду из рационализированных и эффективных операций.', 'Честность: Работа с прозрачностью и честностью, всегда ставя на первое место потребности наших клиентов.', 'Сотрудничество: Содействие духу сотрудничества внутри нашей компании и с нашими партнерами и клиентами для создания решений, которые действительно соответствуют потребностям малых и средних предприятий.'],
        },
        about: {},
        contact: {
            title: "Свяжитесь с нами",
            description: "Мы будем рады услышать вас! Пожалуйста, заполните форму ниже, чтобы связаться с нами.",
            name: "Имя",
            email: "Email",
            message: "Сообщение",
            submit: "Отправить"
        },
        products: {
            title1: "Filon",
            title2: "AutoBMC",
            title3: "Strow",
            title4: "Специализированная Экспертиза для Уникальных Задач",
            content1: "Откройте силу инсайтов, управляемых ИИ, с Filon, нашим сложным инструментом, который оценивает правительственные вызовы с использованием передовой технологии краулеров. Ежедневно получайте персонализированный отчет, наполненный высоко оцененными возможностями, тщательно подобранными в соответствии с вашими бизнес-целями. С Filon вы можете быть на шаг впереди, гарантируя, что вы никогда не пропустите выгодный правительственный контракт или возможность партнерства. Принимайте обоснованные решения и двигайте свой бизнес вперед с уверенностью.",
            content2: "Запустите свой бизнес с беспрецедентной скоростью с AutoBMC, генератором Business Model Canvas (BMC), управляемым ИИ. Созданный опытным консультантом по малому бизнесу и профессором университета, AutoBMC упрощает процесс бизнес-планирования. Просто введите описание вашего проекта, и наша интеллектуальная система сделает остальное, создавая подробный BMC, который поможет вам быстро ориентироваться в рыночных сложностях. Идеально подходит для предпринимателей, которые хотят ускорить путь от концепции к изучению рынка.",
            content3: "Представляем Strow, революционную платформу с открытым исходным кодом, предназначенную для художников и создателей. Используя бесплатные облачные сервисы, Strow позволяет легко размещать и монетизировать онлайн-мероприятия. Независимо от того, хотите ли вы транслировать живой концерт, художественный семинар или интерактивный вебинар, Strow предоставляет необходимые инструменты для глобального взаимодействия с вашей аудиторией. Испытайте полную творческую свободу и финансовый контроль с Strow, где ваше шоу - это только начало.",
            content4: "В Go Cybernation мы не просто создаем решения; мы их совершенствуем. Независимо от того, исследуете ли вы инфраструктуру IoT большого масштаба или уникальные программные решения, наша консультационная служба предлагает персонализированную экспертизу для решения ваших самых сложных задач. Наша команда экспертов тесно сотрудничает с вами, чтобы разрабатывать и внедрять передовые решения, которые так же уникальны, как и ваши потребности. От концепции до развертывания мы являемся вашими партнерами в превращении амбициозных видений в реальность.",
        },
        team: {
            members: [
                {
                    name: "Élyse Morin",
                    role: "Сооснователь",
                    skills: ["Инфраструктура", "Управление проектами", "Автоматизация"],
                    img: "/elysem.jpg"
                },
                {
                    name: "Simon Voyer",
                    role: "Сооснователь",
                    skills: ["Архитектура программного", "ИИ", "Автоматизация"],
                    img: "/simonv.jpg"
                }
            ]
        },
        nav: {
            home: "Главная",
            about: "О нас",
            products: "Продукты",
            team: "Команда",
            contact: "Контакт"
        },
        footer: {},
    },
};

export default i18nData;